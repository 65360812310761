import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme, styled } from '@mui/material/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';

import * as dayjs from 'dayjs'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),

  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));



    

function descendingComparator(a, b, orderBy) {
  let aVal = a[orderBy];
  let bVal = b[orderBy]

  if (!isNaN(a[orderBy])) { // if a number
    aVal = Number(aVal)
    bVal = Number(bVal)
  }
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  // {
  //   id: 'user',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'user',
  // },
  {
    id: 'Radius_Timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Radius Timestamp',
  },
  {
    id: 'Radius_AccessPoint',
    numeric: true,
    disablePadding: false,
    label: 'UM Building',
  },
  {
    id: 'Radius_SSID',
    numeric: true,
    disablePadding: false,
    label: 'Radius SSID',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    // todo - change to use theme
    <TableHead>
      <TableRow>
  
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
            
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({splunkRows, isLoading, errorMsg}) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('Radius_Timestamp');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  // const [splunkRows, setSplunkRows] = useState([]);
  // const [isLoading, setLoading] = useState(false);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = splunkRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - splunkRows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...splunkRows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, isLoading],
  );

  
  if (errorMsg) {
    errorMsg = (
      <Box m={5}>
          <FeedbackIcon color='disabled' sx={{ height: '80px', width: '80px' }}/>
         
            <div>
            Failed to fetch
            </div>
          

        </Box>
    )
  }



  return (
    <Box sx={{ width: '100%', flexGrow:1}}>
  
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
        <Grid padding={1} margin='auto' sx={{width:'75%'}}>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={splunkRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer>
       
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
         
           
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={splunkRows.length}
    
          
      
              
            />
            
             {isLoading ? (
            // CHANGE TO DEPEND ON NUMBER OF FIELDS
            <TableCell colSpan={11}> 
              <Box sx={{ width:'100%'}} >
                  <LinearProgress />
              </Box>
              </TableCell>
       
          
        ): (
         



          
          
            

          <TableBody>
            
            

          {visibleRows.map((row, index) => {
    
            return (
              <TableRow
                tabIndex={-1}
                key={index}
              >
               
                {/* <TableCell align="center">{row.user}</TableCell> */}
              
                <TableCell align="center">{dayjs(row.Radius_Timestamp).format('LLL')}</TableCell>
                <TableCell align="center">{row.Radius_AccessPoint}</TableCell>
                <TableCell align="center">{row.Radius_SSID}</TableCell>
              
            
    
              
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
             
            </TableRow>
          )}
        </TableBody>

        )}
           
          </Table>
        </TableContainer>
        { errorMsg ? 
        (
          errorMsg
        
        ) : ('')
        
        }
        </Grid>
        
       

    
    </Box>
  );
}
