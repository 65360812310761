import './App.css';
import { useState, useEffect } from 'react';
import ButtonAppBar from './Header';
import SimpleBottomNavigation from './Footer.js';
import BasicDateRangePicker from './DatePicker.js';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import { styled } from '@mui/material/styles';
import * as dayjs from 'dayjs';
import { Typography } from '@mui/material';
import EnhancedTable from './SortedSplunkTable.js';
import Cookies from 'js-cookie';
import { decodeToken } from "react-jwt";

const serverUrl = process.env.REACT_APP_SERVER_ENDPOINT || "http://localhost:8000";

const theme = createTheme({
  palette: {
    primary: {
      main: '#00274C'
    }
  }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

function createData(user, Radius_Timestamp, Radius_AccessPoint, Radius_SSID) {
  return { user, Radius_Timestamp, Radius_AccessPoint, Radius_SSID };
}

function App() {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(dayjs());
  const [isLoading, setLoading] = useState(false);
  const [splunkRows, setSplunkRows] = useState([]);
  const [searchClick, setSearchClick] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [jwtToken, setJWTToken] = useState(Cookies.get('jwt'));
  const [uniqname, setUniqname] = useState("");

  useEffect(() => {
    if (Cookies.get('jwt')) {
      setJWTToken(Cookies.get('jwt'));
      setUniqname(decodeToken(jwtToken)['sub']);
    } else {
      setJWTToken('');
    }
  }, [jwtToken]);

  useEffect(() => {
    if (!Cookies.get('jwt')) {
      window.location.href = `${serverUrl}/api/login`;
    }
  }, []);

  function makeFetchRequest() {
    let ignoreStaleRequest = false;
    setSplunkRows([]); // reset before search.
    setLoading(true);
    setErrorMsg('');
    let fetchUrl = `${serverUrl}/api/get-radius-logs/?startTime=${startDate.unix()}&endTime=${endDate.unix()}&jwtToken=${jwtToken}`;
    fetch(fetchUrl, { headers: { 'Access-Control-Allow-Origin': "*" } })
      .then((response) => response.json())
      .then((data) => {
        if (!ignoreStaleRequest) {
          let rowCount = Object.keys(data.results).length;
          for (let rowNum = 0; rowNum < rowCount; rowNum++) {
            let dataRow = data.results[rowNum];
            setSplunkRows((splunkRows) => [...splunkRows, createData(dataRow.user, dataRow.Radius_Timestamp, dataRow.Radius_AccessPoint, dataRow.Radius_SSID)]);
          }
          setLoading(false);
        }
        setSearchClick(0);
        ignoreStaleRequest = true;
      })
      .catch((error) => {
        console.error(error);
        setErrorMsg(`Could not reach backend: ${error}`);
        setLoading(false);
      });
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ButtonAppBar uniqname={uniqname} setJWTToken={setJWTToken} />
        <Box p={2} m={4}>
          <h2>View Your Wireless Connection History on U-M Campuses.</h2>
        </Box>
        <BasicDateRangePicker sendStart={setStartDate} sendEnd={setEndDate} startDate={startDate} endDate={endDate} setSearchClick={setSearchClick} searchDisable={isLoading} makeFetchRequest={makeFetchRequest} />
        <EnhancedTable splunkRows={splunkRows} isLoading={isLoading} errorMsg={errorMsg} />
        { !splunkRows.length && !errorMsg ? (
          <Box m={5}>
            <NetworkWifiIcon color='disabled' sx={{ height: '80px', width: '80px' }} />
            <Typography color='disabled'>Start searching</Typography>
          </Box>
        ) : ('')}
        <SimpleBottomNavigation sx={{ bottom: 0 }} />
      </ThemeProvider>
    </div>
  );
}

export default App;