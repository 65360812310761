import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

export default function BasicDatePicker({sendStart, sendEnd, startDate, endDate, setSearchClick, searchDisable, makeFetchRequest}) {
  // const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'))
  // const [endDate, setEndDate] = useState(dayjs())
  const [validRange, setValidRange] = useState(1)

  function checkDateRangeValid(startDate, endDate) {
    if (dayjs(endDate).isBefore(dayjs(startDate))) {
      setValidRange(0)
    }
    else { // valid case
      setValidRange(1)
      setSearchClick(1)
      makeFetchRequest();

    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     <Grid padding={1} margin='auto' sx={{width:'75%'}}> 
      <Stack direction='row' spacing={2} sx={{width: '100%'}}>
            <DatePicker sx={{margin:1, width:'40%'}} label="Start date" value={startDate} onChange={(newDate) => sendStart(newDate)} disableFuture/> 
            <DatePicker sx={{margin:1, width:'40%'}} label="End date" value={endDate} onChange={(newDate) => sendEnd(newDate)} disableFuture/>
            <Button sx={{width:'20%'}} onClick={() => checkDateRangeValid(startDate, endDate)} variant="outlined" disabled={searchDisable}>
              Search
            </Button>
            {validRange ? ('') : (<Box bgcolor={'#FF474C'} borderRadius={1} padding={2}>Start date must be before end date.</Box>)}
        </Stack>
      </Grid>
      
   
     
         
    </LocalizationProvider>
    
  );
}
