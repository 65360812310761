import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './ITSStyling.module.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
       
      <footer className
="footer container" role="contentinfo" aria-label="Page Footer" style={{bgcolor: 'blue'}}>
    <div className
    ="region region-footer">
    <section id="block-block-1" className
    ="block block-block clearfix">

      
  <div className
  ="umich"><a href="https://umich.edu/">University of Michigan</a></div>

<div className
="links">
<h2><a href="https://its.umich.edu/">Information and Technology Services</a></h2>
<ul>
<li><a href="https://its.umich.edu/about">About ITS</a></li>
<li><a href="https://safecomputing.umich.edu/">Safe Computing</a></li>
<li><a href="https://status.its.umich.edu/">ITS Service Status</a></li>
<li><a href="https://its.umich.edu/about/work-at-its">Work at ITS</a></li>

</ul>
</div>

<div className
="links">
<h2>Stay Connected</h2>
<div className
="socialmedia">
<a href="https://twitter.com/umichTECH"><TwitterIcon/><span className
    ="sr-only">Twitter</span></a>
<a href="https://www.instagram.com/umichtech/"><InstagramIcon/><span className
    ="sr-only">Instagram</span></a>
<a href="https://youtube.com/user/UMITSCommunications"><YouTubeIcon/><span className
    ="sr-only">YouTube</span></a>
<a href="https://www.linkedin.com/company/university-of-michigan-information-and-technology-services"><LinkedInIcon/><span className
    ="sr-only">LinkedIn</span></a>
</div>
<p><a href="https://its.umich.edu/help">Contact ITS</a></p>
</div>

<div className
="links right">
<ul>
<li><a href="https://wolverineaccess.umich.edu/">Wolverine Access</a></li>
<li><a href="https://it.umich.edu/">Office of the <span className
    ="nobr">VPIT-CIO</span></a></li>
<li><a href="https://umich.edu/about/privacy"><span className
    ="nobr">U-M</span> Website Privacy Notice</a></li>
</ul>
</div>

<div className
="copyright"><a href="https://regents.umich.edu/">&copy; 2024 The Regents of the University of Michigan</a></div>
</section>

  </div>
</footer>


  );
}
